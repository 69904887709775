import React, { useContext } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import PortableText from 'react-portable-text';
import { Link } from 'gatsby';

import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid, Typography, useMediaQuery } from '@material-ui/core';

import FormModalContext from '../../context/FormModalContext';
import ReactPlayer from 'react-player';

const useStyles = makeStyles((theme) => ({
  topHeader: {
    color: theme.lightBlue,
    fontWeight: 700,
    [theme.breakpoints.down('md')]: {
      fontSize: '1.5rem',
    },
  },
  bottomHeader: {
    color: theme.workwaveBlue,
    fontWeight: 700,
    // paddingTop: '1rem',
    // paddingBottom: '1rem',
  },
  linkText: {
    color: theme.mediumDarkGray,
  },
  playerWrapper: {
    position: 'relative',
    paddingTop: '56.25%',
    minHeight: '100%',
    minWidth: '100%',
    overflow: 'hidden',
    border: '5px white solid',
    boxShadow:
      '0 13px 27px -5px rgba(50, 50, 93, 0.25), 0 8px 16px -8px rgba(0, 0, 0, 0.3)',
  },
  reactPlayer: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
  pricing: {
    paddingTop: '50px',
    [theme.breakpoints.down('md')]: { paddingTop: '40px' },
    [theme.breakpoints.down('xs')]: { paddingTop: '30px' },
  },
}));

export const ProductOverview = ({
  overviewBody,
  overviewImage,
  pricing,
  overviewCta,
  allSolutions,
  events,
  overviewVideo,
}) => {
  const classes = useStyles();
  const med = useMediaQuery('(max-width:600px)');

  const { handleModalClick } = useContext(FormModalContext);
  return (
    <Grid
      container
      direction='row'
      justifyContent='space-between'
      alignItems='center'
      className={pricing && classes.pricing}
    >
      {!!overviewImage || !!overviewVideo ? (
        <Grid
          item
          container
          direction='row'
          alignItems='center'
          justifyContent='center'
          style={{ marginBottom: med && '2rem' }}
          xs={12}
          md={5}
        >
          {overviewVideo ? (
            <div className={classes.playerWrapper}>
              <ReactPlayer
                url={overviewVideo}
                className={classes.reactPlayer}
                height='100%'
                width='100%'
                controls={true}
              />
            </div>
          ) : (
            <GatsbyImage image={overviewImage?.asset?.gatsbyImageData} />
          )}
        </Grid>
      ) : null}
      <Grid
        item
        container
        xs={12}
        md={!overviewImage && !overviewVideo ? 12 : 7}
        direction='column'
        justifyContent='center'
        alignItems='center'
      >
        {/* <Grid item>
					<Typography
						variant='h2'
						color='primary'
						style={{
							lineHeight: 1.4,
						}}>
						{overviewHeader}
					</Typography>
				</Grid> */}
        <Grid
          item
          style={{
            zIndex: 1000,
            textAlign: events ? 'center' : null,
            padding: !med && '0 3rem',
          }}
        >
          <PortableText
            content={overviewBody}
            serializers={{
              h2: ({ children }) => (
                <Typography
                  variant='h2'
                  className={classes.bottomHeader}
                  style={{ textAlign: allSolutions ? 'center' : 'left' }}
                >
                  {children}
                </Typography>
              ),
              h3: ({ children }) => (
                <Typography
                  variant='h3'
                  color='primary'
                  style={{ paddingBottom: '1rem' }}
                >
                  {children}
                </Typography>
              ),
              h4: ({ children }) => (
                <Typography variant='h4' className={classes.topHeader}>
                  {children}
                </Typography>
              ),
              normal: ({ children }) => (
                <Typography
                  variant='body1'
                  style={{
                    color: '#4B5B69',
                    lineHeight: 1.7,
                    // fontFamily: 'Roboto',
                    padding: !overviewImage && !overviewVideo && '0 6rem',
                    margin:
                      !overviewImage && !overviewVideo && !med && '1rem auto',
                  }}
                >
                  {children}
                </Typography>
              ),
              emailPhoneLink: (props) => {
                return (
                  <a href={props.emailPhoneHref} className={classes.linkText}>
                    {props.children[0]}
                  </a>
                );
              },
              internalLink: ({ children, internalSlug }) => (
                <Link to={`/${internalSlug}`} className={classes.linkText}>
                  {children}
                </Link>
              ),
              strong: ({ children }) => (
                <span style={{ color: '#D10066' }}>{children}</span>
              ),
            }}
          />
          {overviewCta ? (
            <Button
              size='large'
              color='primary'
              variant='contained'
              style={{ marginTop: '1rem' }}
              onClick={handleModalClick}
            >
              {overviewCta}
            </Button>
          ) : null}
        </Grid>
      </Grid>
    </Grid>
  );
};
