import React, { useContext, useEffect } from 'react';
import { graphql } from 'gatsby';

import { Button, Container } from '@material-ui/core';

import { GeneralHero } from '../components/General/GeneralHero';
import { SectionBlue } from '../components/Sections/SectionBlue';
import { SectionWhite } from '../components/Sections/SectionWhite';
import { HeroWaveTwo } from '../components/Waves/HeroWaveTwo';
import { ProductOverview } from '../components/ProductFeature/ProductOverview';
import { SEO } from '../components/SEO';
import Form from '../components/ContactForm/Form';

import LocaleContext from '../context/LocaleContext';
import { EventsBody } from '../components/Events/EventsBody';

const EventsPage = ({ data: { events }, location }) => {
  const { handleRedirect, ipData } = useContext(LocaleContext);

  const {
    hero,
    _rawHeader,
    eventLabels,
    eventsCards,
    metaTitle,
    metaDescription,
    ctaText,
    ctaLink,
    contactForm,
    pardotUrl,
  } = events;

  useEffect(() => {
    ipData && handleRedirect(location.pathname, true);
  }, [ipData]);

  return (
    <>
      <SEO title={metaTitle} description={metaDescription} />

      <SectionBlue top extraBottom>
        <GeneralHero heroData={hero} />
        <HeroWaveTwo />
      </SectionBlue>
      <SectionWhite>
        <Container>
          <ProductOverview overviewBody={_rawHeader} events />
          <EventsBody events={eventsCards} eventLabels={eventLabels} />
        </Container>
      </SectionWhite>

      <SectionWhite marginTop='40px' zIndex={3}>
        {/* <SectionWaveDefault top='0px' fillColorBorder='#FFF' /> */}
        {/* <Resources
          header={resourceTitle}
          subheader={_rawResourceBody}
          resources={resources}
        /> */}
        <Button
          component='a'
          href={ctaLink}
          target='_blank'
          size='large'
          color='primary'
          variant='contained'
          style={{ padding: '1rem 4rem' }}
        >
          {ctaText}
        </Button>
      </SectionWhite>
      <Form
        pardotUrl={pardotUrl}
        contactForm={contactForm}
        location={location}
        noForm
      />
    </>
  );
};

export default EventsPage;

export const query = graphql`
  query ($slug: String!) {
    events: sanityEvents(slug: { current: { eq: $slug } }) {
      metaTitle
      metaDescription
      _type
      id
      slug {
        current
      }
      language
      hero {
        softwareLogo {
          asset {
            gatsbyImageData(placeholder: BLURRED, fit: FILLMAX)
          }
        }
        _rawContent
        heroImage {
          asset {
            gatsbyImageData(placeholder: BLURRED, width: 700)
          }
        }
        showEmailForm
      }
      _rawHeader
      eventLabels
      eventsCards: events {
        title
        header
        content
        description
        ctaText
        ctaLink
        image {
          asset {
            gatsbyImageData(placeholder: BLURRED, width: 800)
          }
        }
        domestic
        international
        webinar
      }
      ctaText
      ctaLink
      contactForm {
        header
        privacyPolicy
      }
      pardotUrl
      resourceTitle
      _rawResourceBody
      resources {
        title
        blurb
        image {
          asset {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
        ctaText
        ctaLink
      }
    }
  }
`;
